@import '@core/styles/mixins/dark-mode';

.EndpointPage {
  @media (max-width: 1200px) {
    --SuperHubEditorFormLayout-divider: none;
    --SuperHubEditorFormLayout-grid: 1fr;
  }

  &-request-response-sidebar {
    @media (min-width: 1201px) {
      padding-left: var(--md);
    }

    @media (max-width: 1201px) {
      border-top: 1px solid var(--color-border-default);
    }
  }
}

.EndpointBanner {
  &-link {
    color: inherit;
    text-decoration: underline;

    &:hover {
      color: var(--green10);
    }
  }

  &-tooltip {
    text-decoration: underline;

    &:hover {
      cursor: help;
    }
  }
}

.EndpointLimitedNotification {
  align-items: flex-start;
  max-width: 400px;

  &-link {
    color: inherit;
    text-decoration: underline;
  }
}