.GitHistoryList {
  box-sizing: border-box;
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
  min-height: 0;
  padding: var(--SuperHub-margin);

  &_loading {
    align-items: center;
    color: var(--color-text-minimum);
    display: flex;
    justify-content: center;
  }

  &-header {
    align-items: center;
    border-bottom: 1px solid var(--color-border-default);
    display: flex;
    justify-content: space-between;
    padding-bottom: var(--sm);
  }

  &-title {
    align-items: center;
    color: var(--color-text-default);
    display: flex;
    font-size: 14px;
    font-weight: var(--font-weight-bold);
    gap: var(--xs);
    margin: 0;
  }

  &-list {
    color: var(--color-text-default);
    height: 100%;
    margin: 0;
    min-height: 0;
    overflow: hidden;
    padding: 0;
    transition: opacity var(--transition-fast);

    &_disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &-footer {
    border-top: 1px solid var(--color-border-default);
    padding-top: var(--sm);
  }
}
