.ApiDefinitionCreateOrUpdate {
  &-spinner {
    padding: var(--xl) 0;
  }

  &-code {
    background: var(--color-bg-hover);
    border-radius: 4px;
    color: var(--color-text-default);
    font-size: 0.75em;
    padding: 2px 3px;
  }

  &-apikeys {
    border: 1px solid var(--color-border-default);
    border-radius: var(--border-radius-lg);
    box-sizing: border-box;
  }

  &-apikeys-header,
  &-apikeys-row {
    align-items: center;
    display: grid;
    gap: var(--xs);
    grid-template-columns: 1fr 1fr;
    padding: 3px;
  }

  &-apikeys-header-label {
    color: var(--color-text-minimum);
    font-size: 10px;
    font-weight: var(--font-weight-bold);
    letter-spacing: .025em;
    padding: 0 var(--xs);
    text-transform: uppercase;
  }

  &-apikeys-row {
    border-top: 1px solid var(--color-border-default);

    :global(.FormGroup + .FormGroup) {
      margin-top: 0;
    }

    &-input {
      :global(.FormGroup_error) & {
        border-color: var(--red) !important;
      }
      
      &:not(:active):not(:focus):not(:hover) {
        border-color: transparent;
      }
    }
  }

  &-form-footer {
    margin-top: 30px;
  }
}
