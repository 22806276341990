@import '@core/styles/vars';

.MyRequestsPreview {
    position: relative;

    img {
      margin-top: var(--md);
      max-width: 100%;
    }

    &-btn {
      bottom: 0;
      left: 50%;
      position: absolute;
      transform: translate(-50%, -50%);

      &:disabled,
      &:hover {
        background-color: var(--color-bg-page);
      }

      &:disabled {
        opacity: 1 !important;
      }
    }

    &-banner {
        border: 1px solid var(--color-border-default);
        border-radius: var(--border-radius);
        color: var(--color-text-minimum);
        font-size: 12px;
        font-weight: var(--font-weight-normal);
        margin-bottom: var(--md);
        padding: var(--xs);
    
        :global(.Button) {
          margin-left: auto;
        }
    }
}
