.TypeSelector {
  $block: &;

  // For changelog types, we render filled icons next to its label.
  &_section-changelog {
    #{$block}-icon-wrapper {
      opacity: 1 !important;
    }

    #{$block}-icon {
      border-radius: var(--border-radius);
      color: var(--white);
      padding: 2px;

      &_added {
        background: var(--green);
      }

      &_fixed {
        background: var(--blue);
      }

      &_improved {
        background: var(--purple);
      }

      &_deprecated {
        background: var(--yellow);
      }

      &_removed {
        background: var(--red);
      }
    }
  }
}
