.AsidePanel {
  bottom: 0;
  position: fixed;
  right: 0;
  top: var(--SuperHub-top-nav-height);
  width: var(--SuperHub-aside-width);

  &_standalone {
    top: 0;
  }

  &-root{
    height: 100%;
  }
}
