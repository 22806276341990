.PageError {
  &-message {
    font-size: 12px;
    margin: 0;
    overflow: auto;
  }

  &-help {
    color: var(--red);
    font-size: 11px;
    margin-top: 3px;

    &-link {
      color: var(--red);
      cursor: pointer;
      font-weight: var(--font-weight);
      text-decoration: underline;

      &:hover {
        color: var(--red40);
      }
    }
  }
}
