@import '@core/styles/mixins/dark-mode';

.ConfigBlock {
  --ConfigBlock-bg: var(--gray100);

  @include dark-mode {
    --ConfigBlock-bg: var(--gray15);
  }

  background: var(--ConfigBlock-bg);
  border: 1px solid var(--color-border-default);
  border-radius: var(--border-radius-lg);
  box-shadow: 0 5px 10px #{rgba(black, 0.05)}, 0 2px 6px #{rgba(black, 0.025)}, 0 1px 3px #{rgba(black, 0.025)};
  display: flex;
  flex-direction: column;
  gap: var(--xs);
  height: fit-content;
  padding: var(--xs);


  &-label {
    color: var(--color-text-minimum);
    font-size: 11px;
    font-weight: var(--font-weight);
    margin-right: var(--xs);
  }

  &-menu {
    max-height: 300px;
    overflow-y: auto;
  }

  figure {
    margin: 0;
    pointer-events: none;
    user-select: none;
  }

  &-overlayPreviewBtn {
    opacity: 0;
    visibility: hidden;

    &:hover {
      cursor: default;
    }
  }

  &-overlayContainer {
    position: relative;

    &:hover .ConfigBlock-overlayPreviewBtn {
      opacity: 1;
      transition: visibility var(--transition-slow) var(--transition-timing),
        opacity var(--transition-slow) var(--transition-timing);
      visibility: visible;
    }
  }

  &-overlay {
    align-items: center;
    background: rgba(var(--color-bg-page-rgb), 0.6);
    display: flex;
    height: 100%;
    justify-content: center;
    position: absolute;
    width: 100%;
    z-index: 1;
  }
}

.ConfigBlockImage {
  border-radius: var(--border-radius-lg);
  font-size: 12px;
  padding: 0 var(--sm) var(--sm);

  &_no-padding {
    padding: 0;
  }

  &_dark {
    background-color: var(--gray10);

    [class*='APISectionHeader-heading'] {
      color: var(--gray80) !important;
    }
  }

  &-heading {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0 var(--sm);

    &_dark {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    &_borderless {
      border: 0;
    }
  }

  &-button {
    letter-spacing: 0;

    &_no-padding {
      height: unset;
      padding: 0;
    }
  }

  &-section {
    padding: 0 var(--sm) var(--sm);
  }

  &-metric-section {
    padding: 0 0 var(--sm);
  }

  &-skeleton-row {
    display: flex;
    font-size: var(--sm);
    gap: var(--sm);
    margin-bottom: var(--sm);
    margin-left: var(--sm);

    + .ConfigBlockImage-skeleton-row {
      padding-left: 40px;
    }
  }

  &-rule {
    border: 0;
    border-top: 1px solid var(--color-border-default);
    margin: 0;
  }

  &-list {
    margin: 0 var(--sm);
    overflow: clip;
    padding: 0;

    > * {
      padding: var(--sm);

      + * {
        border-top: 1px solid var(--color-border-default);
      }
    }
  }

  &-footer {
    padding: var(--sm);

    &_dark {
      border-top: 1px solid rgba(255, 255, 255, 0.1);
    }

    &_borderless {
      border: 0;
    }
  }

  &-avatar {
    background: var(--purple);
  }

  &-stretch-col {
    flex: 1;
  }

  :global(.Icon) {
    flex: 0 0 auto;
  }

  &-title {
    font-size: 18px;

    span {
      color: var(--gray60);
      margin-right: var(--sm);
    }
  }

  &-image {
    width: 100%;
  }

  &-label {
    color: var(--color-text-minimum);
    font-size: 14px;
    font-weight: var(--font-weight);
    line-height: 1.6;
  }
}
