.ApiDefinitionImporter {
  $block: &;

  align-items: center;
  display: flex;

  &:only-child {
    justify-content: center;

    #{$block}-list {
      width: 350px;
    }
  }

  &-list {
    border: 1px solid var(--color-border-default);
    border-radius: var(--sm);
    max-width: 100%;
  }

  &-item {
    align-items: stretch;
    padding: var(--sm);

    + #{$block}-item {
      border-top: 1px solid var(--color-border-default);
      margin-top: 0;
    }

    &-name {
      color: var(--color-text-default);
      font-size: 16px;
      font-weight: var(--font-weight-bold);
    }

    &-description {
      color: var(--color-text-minimum);
      font-size: 12px;
    }

    &-upload {
      border-color: transparent;
      color: var(--blue);
      font-size: 14px;
      height: var(--button-sm);
      padding: 0 var(--sm);
      margin: auto 0;
      min-width: 0;
      width: auto;

      :global(.IconWrapper) {
        display: none;
      }

      :global(.Button-label) {
        margin-left: 0;
      }
    }

    &-input {
      width: 100%;
    }
  }
}
