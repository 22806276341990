@import '@core/styles/vars';

.SidebarNav {
  border-right: 1px solid var(--color-border-default);
  flex: 0 0 auto;
  width: var(--hub-sidebar-width);

  &_loading {
    align-items: center;
    display: flex;
    font-size: 4rem;
    justify-content: center;
    padding: 0;
  }
}
