.SuperHubEditorFormLayout {
  --SuperHubEditorFormLayout-divider: var(--color-border-default);
  --SuperHubEditorFormLayout-grid: minmax(200px, 800px) 420px;
  --SuperHubEditorFormLayout-sidebar-gap: var(--md);

  height: 100%;
  position: relative;

  &-content {
    flex: 1 1 auto;
    padding: var(--md) 30px var(--xl);

    &_with-sidebar {
      align-items: stretch;
      display: grid;
      gap: var(--SuperHubEditorFormLayout-sidebar-gap);
      grid-template-columns: var(--SuperHubEditorFormLayout-grid);
    }
  }

  &-main {
    position: relative;

    &::after {
      background-color: var(--SuperHubEditorFormLayout-divider);
      content: '';
      height: 100%;
      left: calc(100% + var(--SuperHubEditorFormLayout-sidebar-gap));
      position: absolute;
      top: 0;
      width: 1px;
      z-index: 1;
    }
  }

  &-sidebar {
    position: sticky;
    top: var(--sm);
  }

  &-notification {
    bottom: 100%;
    box-sizing: border-box;
    display: flex;
    flex: 0 0 auto;
    justify-content: flex-end;
    left: 0;
    padding: var(--sm);
    position: fixed;
    width: 100%;
    z-index: 1;
  }

  &-actions {
    align-items: center;
    backdrop-filter: blur(var(--md));
    background: rgba(var(--color-bg-page-rgb), 0.1);
    border-top: 1px solid var(--color-border-default);
    bottom: 0;
    box-sizing: border-box;
    display: flex;
    flex: 0 0 auto;
    height: var(--SuperHubEditor-footer);
    justify-content: flex-end;
    padding: var(--sm);
    position: sticky;
    width: 100%;
    z-index: 1;
  }

  &-controls {
    :global(.FormGroup + .FormGroup) {
      margin-top: 0;
    }
  }

  :global(.Toggle-label) {
    font-size: 14px;
  }

  &Header {
    border-bottom: 1px solid var(--color-border-default);
    display: flex;
    flex-flow: nowrap column;
    gap: 15px;
    max-width: var(--hub-main-max-width);
    padding-bottom: 30px;
    padding-top: var(--md);
  }

  &Title {
    flex: 1;
    margin: 0;
  }

  &Body {
    max-width: var(--hub-main-max-width);
    padding-top: var(--sm);
  }
}
