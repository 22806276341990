.WebhookConfigMenu {
  &-label :global(.Icon) {
    margin-right: 0;
  }

  &-description {
    font-size: 12px;
    opacity: 0.5;
  }
}
