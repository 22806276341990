.GitHistoryListItem {
  $root: &;

  box-sizing: border-box;
  font-size: 11px;

  &-button {
    align-items: center;
    all: unset;
    border-radius: var(--border-radius);
    box-sizing: border-box;
    cursor: pointer;
    display: grid;
    gap: var(--md);
    grid-template-columns: auto 1fr;
    line-height: 28px;
    padding: 0 var(--xs);
    transition: all var(--transition-fast);
    width: 100%;

    &_active {
      background: rgba(var(--blue-rgb), 0.2);
      color: var(--blue);
      pointer-events: none;
    }

    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }

    &:focus-visible {
      box-shadow: 0 0 0 2px var(--color-focus-ring);
    }
  }

  &-createdAt {
    text-transform: uppercase;
  }

  &-author {
    color: var(--color-text-muted);
    overflow: hidden;
    text-align: right;
    text-overflow: ellipsis;
    transition: color var(--transition-fast);
    white-space: nowrap;

    #{$root}-button_active & {
      color: var(--blue);
    }
  }

  &-spinner {
    color: var(--color-text-minimum);
    margin-top: var(--xs);
  }
}
