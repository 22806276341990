.SidebarNavList {
  list-style: none;
  margin: var(--xs) 0 0;
  padding: 0;

  &Item {
    box-sizing: border-box;
    padding: 0 var(--xs);
  }

  // virtualized list container needs to have extra padding to account for the footer
  > div {
    padding-bottom: var(--SuperHubEditor-footer);
  }
}
