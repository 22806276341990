@import '@core/styles/mixins/dark-mode';

.MetadataModal {
  --MetadataModal-background: var(--white);
  --MetadataModal-image-background: var(--gray100);

  @include dark-mode {
    --MetadataModal-background: var(--gray10);
    --MetadataModal-image-background: var(--gray15);
  }

  position: relative;

  &-header {
    border-bottom: 0;

    &-title {
      font-size: 22px;
    }

    &-body {
      color: var(--color-text-muted);
      margin-bottom: 0;
      margin-top: 3px;
    }
  }

  div.MetadataModal-body {
    background: var(--MetadataModal-background);
    display: flex;
    flex-direction: column;
    gap: var(--md);
    padding-bottom: var(--xs);
    padding-top: var(--xs);
  }

  footer.MetadataModal-footer {
    background: var(--MetadataModal-background);
    border-top: 0;
  }

  &-form-row {
    display: grid;
    gap: 50px;
    grid-template-columns: 1fr 1fr;

    @media screen and (max-width: 600px) {
      gap: var(--md);
      grid-template-columns: 1fr;
    }
  }

  &-image {
    align-items: center;
    background-color: var(--MetadataModal-image-background);
    background-position: center;
    background-size: cover;
    border-radius: var(--border-radius);
    display: flex;
    height: 128px;
    justify-content: center;
    overflow: clip;

    &-controls {
      margin-top: var(--sm);

      &-upload {
        min-width: 0;
      }
    }
  }

  :global {
    hr {
      width: 100%;
    }

    .FormGroup + .FormGroup {
      margin-top: 0;
    }

    .Select {
      width: 100%;
    }
  }
}
