@import '@core/styles/mixins/dark-mode';

.ApiDefinitionList {
  margin: 0 auto;
  max-width: 800px;
  width: 100%;

  &-title {
    color: var(--color-text-default);
    font-size: 24px;
    font-weight: var(--font-weight-bold);
    margin: 0;
  }

  &-list {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

.ApiDefinitionListItem {
  border: 1px solid var(--color-border-default);
  border-radius: var(--border-radius);
  box-sizing: border-box;

  &-header {
    padding: var(--sm) var(--md) 0;
  }

  &-label {
    color: var(--color-text-default);
    font-size: 16px;
    font-weight: var(--font-weight-bold);
  }

  &-dropdown-button {
    height: 25px;
    width: 25px;
  }

  &-status {
    @include dark-mode {
      background-color: var(--gray10);
    }

    $status: &;

    color: var(--color-text-minimum);
    font-size: 12px;

    &-info {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      gap: var(--xs);
      padding: 0 var(--md) var(--sm);

      &_loading {
        justify-content: center;
      }
    }

    &-error {
      align-items: flex-start;
      background: rgba(black, 0.015);
      border-top: 1px solid var(--color-border-default);
      display: flex;
      flex-direction: column;
      margin: 0;
      overflow: hidden;
      padding: var(--md);
      white-space: pre-wrap;

      &-code {
        color: var(--color-text-default);
        font-family: var(--font-family-mono);
        line-height: 1.3;
        max-height: 12lh; // enough for 1 big error (~12 lines)
        overflow: hidden;

        &_expanded {
          max-height: none;
        }
      }

      &-button {
        font-family: var(--font-family);
        margin-top: var(--sm);
      }
    }
  }
}

.ApiDefinitionType {
  &-title {
    color: var(--color-text-muted);
    font-size: 12px;
    font-weight: var(--font-weight-normal);
  }
}
