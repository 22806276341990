@import '@core/styles/vars';

.SuperHubEditor {
  --SuperHubEditor-footer: 50px;

  flex: 1 1 auto;
  min-height: 0;

  &-nav {
    min-height: 45px;
  }

  &-container {
    flex: 1 1 auto;
    min-height: 0;
  }

  &-content {
    box-sizing: border-box;
    height: 100%;
    overflow: auto;
    width: 100%;
  }

  &-gate-content {
    display: flex;
  }

  &-spinner {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
  }
}
