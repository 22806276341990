.HeaderInput {
  all: unset;
  background-color: transparent;
  border-radius: var(--border-radius);
  box-shadow: 0 0 0 5px transparent;
  overflow-y: hidden;
  transition:
    background var(--transition-slow) ease,
    box-shadow var(--transition-slow) ease;
  width: 100%;

  &:not(:disabled):hover,
  &:not(:disabled):focus {
    background-color: rgba(var(--gray50-rgb), 0.1);
    box-shadow: 0 0 0 5px rgba(var(--gray50-rgb), 0.1);
  }

  &:disabled {
    background: none;
    cursor: default;
  }

  &_name-content.excerpt {
    font-size: 18px;
    margin: 0;
  }
}
