.APIConfigOnboarding {
  $root: &;

  display: grid;
  gap: var(--md);
  grid-template-columns: minmax(340px, 33%) 1fr;
  margin-bottom: var(--md);
  margin-top: var(--lg);

  &_with-padding {
    padding: 0 30px;
  }

  &-left-col {
    margin-top: var(--md);
  }

  &-right-col {
    border-radius: var(--border-radius-lg);
    overflow: hidden;
    position: relative;
  }

  &_centered {
    grid-template-columns: 1fr;

    #{$root}-left-col {
      margin-left: auto;
      margin-right: auto;
      max-width: 420px;
      text-align: center;
      width: 100%;
    }
  }

  &-bg {
    bottom: 0;
    height: 485px;
    position: absolute;
    transform: scale(0.95);
    transition: transform 0.5s var(--transition-timing);
    width: auto;
    z-index: 1;

    &_initial {
      pointer-events: none;
      transform: scale(1);
      user-select: none;
    }
  }

  &-snippet {
    border-radius: var(--border-radius-lg);
    height: 500px;
    overflow: clip;
    position: relative;
    transform: translate(0, 0);
    transition: transform 0.5s var(--transition-timing);
    z-index: 2;

    &_initial {
      pointer-events: none;
      transform: translate(400px, 0);
      user-select: none;
    }
  }

  &-badge {
    margin-bottom: var(--sm);

    &_my-developers {
      --Badge-bg: var(--green);
    }
  }

  &-badge-icon {
    margin-right: 3px;
  }

  &-heading {
    align-items: center;
    color: var(--color-text-default);
    display: flex;
    font-size: 20px;
    font-weight: var(--font-weight);
    line-height: 1.2;
    margin: 0;

    &_centered {
      justify-content: center;
    }
  }

  &-subheading {
    color: var(--color-text-muted);
    font-size: 16px;
    line-height: 1.4;
    margin: var(--xs) 0 var(--sm);

    a {
      color: var(--color-text-muted);
      text-decoration: underline;
    }
  }

  &-intro {
    display: grid;
    grid-template-rows: 1fr auto;
    height: 100%;
  }

  &-intro-footer {
    border-top: 1px solid var(--color-border-default);
    padding: var(--sm) 0;

    &-icon {
      opacity: 0.5;
    }
  }

  &-progress-bar {
    margin-bottom: var(--sm);
    width: 50px;
  }

  &-endpoint-picker {
    box-shadow: none;
    max-height: 300px;
    max-width: 420px;
    overflow-y: auto;
  }

  &-endpoint-picker-item-inner {
    min-width: 0;
    width: 100%;
  }

  &-endpoint-picker-truncate {
    flex: 1;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }

  &-endpoint-picker-category {
    color: var(--color-text-minimum);
    font-size: 10px;
  }

  &-endpoint-picker-page {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  &-empty-menu {
    padding: 5px 0;

    &-item {
      padding: var(--xs) var(--sm);
    }
  }

  &-menu-spinner {
    margin-left: var(--sm);
    max-width: 14px;
  }
}
