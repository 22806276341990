@import '@core/styles/vars';

.SuperHubReference {
  &-article-container {
    flex-direction: column;
    position: relative;
    width: 100%;

    @media (min-width: $ipad-mini-portrait + 1) {
      flex-direction: row;
    }
  };
}
