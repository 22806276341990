.ApiDefinitionForm {
  flex: 1 1 auto;
  height: 100%;
  margin: 0 auto;
  max-width: 600px;
  width: 100%;

  &-header {
    text-align: center;
    width: 100%;

    @media (max-width: 970px) {
      margin: auto;
      max-width: 270px;
      text-align: left;
    }

    &-title {
      color: var(--color-text-default);
      font-size: 24px;
      margin: 0 0 var(--xs);
    }

    &-description {
      color: var(--color-text-minimum);
      font-size: 15px;
      line-height: 1.5;
      margin: 0 auto var(--xs);
      width: 320px;
    }

    &-image {
      margin: 0 0 var(--sm);
    }
  }

  &-body {
    @media (max-width: 970px) {
      flex-direction: column;

      // extra specific for Flex
      &[justify="between"] {
        align-items: center;
      }
    }
  }

  &-createNew,
  &-importer {
    flex: 1 1 50%;
    overflow: hidden;
    padding: var(--lg) var(--md);

    @media (max-width: 970px) {
      padding: var(--md);
    }
  }

  &-createNew {
    @media (max-width: 970px) {
      max-width: 270px;
      width: 100%;
    }

    &-title,
    &-description {
      margin: 0;
    }

    &-button {
      padding-left: 0;
    }

    &-disabled {
      color: var(--color-text-minimum);
      font-size: 12px;
    }
  }

  &-divider {
    background: linear-gradient(rgba(black, 0), rgba(black, 0.15), rgba(black, 0));
    flex: 0 0 1px;
    position: relative;

    @media (max-width: 970px) {
      width: 270px;
    }

    &-or {
      background: var(--color-bg-page);
      color: var(--color-text-minimum);
      font-size: 12px;
      inset: auto;
      padding: 0.5em 0;
      position: absolute;

      @media (max-width: 970px) {
        padding: 0 0.5em;
      }
    }
  }

  &-tooltip {
    cursor: help;

    /* Waves are different in Safari, for some reason, so this shorthand is for all other browsers */
    /* stylelint-disable order/properties-alphabetical-order */
    /* stylelint-disable declaration-block-no-shorthand-property-overrides */
    text-decoration: underline wavy var(--gray70);

    /* SAFARI */
    text-decoration-color: var(--gray70);
    text-decoration-line: underline;
    text-decoration-style: wavy;

    &-content {
      text-align: left;
      width: 250px;

      p {
        margin: 0;

        + p {
          margin-top: 1em;
        }
      }

      &-link {
        $link: &;

        color: var(--white);
        text-decoration: none;

        &:hover,
        &:active,
        &:focus {
          color: var(--white);

          #{$link}-icon {
            opacity: 1;
          }
        }

        &-icon {
          opacity: 0.5;
        }
      }
    }
  }
}
