.GitHistoryDiff {
  color: var(--color-text-default);
  margin: 0;
  min-height: 100%;
  white-space: normal;
  width: 100%;

  &_loading,
  &_error {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  &_ready {
    box-sizing: border-box;
    font-family: var(--font-family-mono);
    font-size: 14px;
    height: 100%;
    line-height: 1.5;
    overflow: auto;
    padding: var(--md);
  }

  :global {
    .diff-remove {
      background-color: rgba(var(--red-rgb), 0.1);
      color: var(--red40);
    }

    .diff-add {
      background-color: rgba(var(--green-rgb), 0.1);
      color: var(--green40);
    }
  }
}
